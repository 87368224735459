import React, { useContext, useState } from "react";
import { GlobalDataContext } from "../../context/context";

const Form = (props) => {
  const { rpdata } = useContext(GlobalDataContext);
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    service: "",
    contactMethod: "",
    message: "",
  });

  const [showLoader, setShowLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setShowLoader(true);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "formSubmit",
      formName: "contactForm",
    });

    const emailBody = {
      toEmail: rpdata?.dbPrincipal?.emails?.map((item) => item.email) || [],
      nombre: formData.name,
      lastname: formData.lastName,
      email: formData.email,
      asunto: `New Service Request From ${formData.name} ${formData.lastName}`,
      phone: formData.phone,
      address: formData.address,
      service: formData.service,
      mensaje: formData.message.length > 0 ? formData.message : "No Extra Info",
      img: rpdata?.dbPrincipal?.logo,
      color: "1",
      waytocontact: formData.contactMethod,
    };

    // Envía el formulario a tu servidor
    fetch("https://email-server-r-8fb46242f2ca.herokuapp.com/email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailBody),
    })
      .then((response) => response.json())
      .then((data) => {
        setShowLoader(false);
        setShowModal(true);
        setFormData({
          name: "",
          lastName: "",
          email: "",
          phone: "",
          address: "",
          service: "",
          contactMethod: "",
          message: "",
        });
        console.log("Success:", data);
      })
      .catch((error) => {
        setShowLoader(false);
        console.error("Error:", error);
      });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="p-2">
      <form onSubmit={handleFormSubmit} className="w-full flex flex-col">
        <div className="content_flex">
          <div className="input_wrap">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleInputChange}
              className="border p-2 m-2"
              required
            />
          </div>
          <div className="input_wrap">
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleInputChange}
              className="border p-2 m-2"
              required
            />
          </div>
        </div>
        <div className="content_flex">
          <div className="input_wrap">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
              className="border p-2 m-2"
              required
            />
          </div>
          <div className="input_wrap">
            <input
              type="tel"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleInputChange}
              className="border p-2 m-2"
              required
            />
          </div>
        </div>
        <div className="content_inputs">
          <div className="input_wrap_full">
            <input
              type="text"
              name="address"
              placeholder="Address"
              value={formData.address}
              onChange={handleInputChange}
              className="border p-2 m-2 w-full"
              required
            />
          </div>
        </div>
        <div className="content_inputs">
          <div className="input_wrap_full">
            <select
              name="service"
              value={formData.service}
              onChange={handleInputChange}
              className="border p-2 m-2 w-full"
              required
            >
              <option value="">Select The Service You Need</option>
              {rpdata?.dbServices?.slice(0, 6).map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="content_margin_checkbox">
          <label className="span_tile">Best Way To Contact You</label>
          <div className="content_checkboxs">
            <div className="content_radio">
              <input
                type="radio"
                name="contactMethod"
                value="Email"
                checked={formData.contactMethod === "Email"}
                onChange={handleInputChange}
                className="m-2"
                required
              />
              <label>Email</label>
            </div>
            <div className="content_radio">
              <input
                type="radio"
                name="contactMethod"
                value="Phone"
                checked={formData.contactMethod === "Phone"}
                onChange={handleInputChange}
                className="m-2"
                required
              />
              <label>Phone</label>
            </div>
          </div>
        </div>
        <div className="content_inputs">
          <div className="input_wrap_full">
            <textarea
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleInputChange}
              className="border p-2 m-2 w-full"
            ></textarea>
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="bg-blue-500 text-white p-2 rounded-md m-2"
          >
            {showLoader ? "Sending..." : "Send A Message"}
          </button>
        </div>
      </form>

      {showLoader && (
        <div className="loader-overlay" style={{ display: "flex" }}>
          <span className="loader-send"></span>
        </div>
      )}

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              fill="currentColor"
              className="bi bi-check-circle"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L7.03 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.071-1.05z" />
            </svg>
            <p>Message sent successfully!</p>
            <button className="modal-button" onClick={closeModal}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Form;

// Añadir estilos CSS

const styles = `
.content_flex {
  display: flex;
  text-align: start;
  flex-direction: row;
  gap: 20px;
}

.input_wrap {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input_wrap_full {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.content_checkboxs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  text-align: start;
  gap: 50px;
}

.content_margin_checkbox {
  margin: 20px 0;
  text-align: start;
}

.content_radio {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.input_style {
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 6px;
  border: none;
  background-color: #f9f9f9;
  transition: border 0.3s, box-shadow 0.3s; /* Agrega una transición suave para border y box-shadow */
}

.input_style:focus {
  outline: none; /* Remueve el outline predeterminado */
  border: 1px solid #b3e5fc; /* Cambia el color del borde a un celeste claro */
  box-shadow: 0 0 0 2px #b3e5fc; /* Opcional: agrega un box-shadow para reforzar el efecto visual */
}

.span_tile {
  font-size: 18px;
  font-weight: bold;
}

.button_submit {
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 10px 30px;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  border-radius: 6px;
  border: none;
  font-size: 18px;
  color: #fff;
  background: linear-gradient(180deg, #4b91f7 0%, #367af6 100%);
  background-origin: border-box;
  box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25),
    inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  transition: transform 0.2s, background-color 0.2s; /* Suaviza la transición de transform y background-color */
}

.button_submit:hover {
  transform: scale(1.05); /* Hace el botón un poco más grande */
  background: linear-gradient(180deg, #3a79f7 0%, #2a68e6 100%); /* Color más oscuro para el hover */
  cursor: pointer;
}

.button_submit:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2),
    0px 0.5px 1.5px rgba(54, 122, 246, 0.25),
    0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}

.button_submit:active {
  transform: scale(0.95); /* Hace el botón un poco más pequeño al hacer clic */
}

/* Ajustes en los estilos para el overlay del loader y el modal */
.loader-overlay {
  display: none; /* Oculto por defecto */
  position: fixed; /* Se queda en su lugar */
  top: 0;
  left: 0;
  width: 100%; /* Ancho completo */
  height: 100%; /* Alto completo */
  background-color: rgba(255, 255, 255, 0.8); /* Overlay blanco con opacidad */
  z-index: 999; /* Asegura que esté sobre todo lo demás */
  justify-content: center;
  align-items: center;
}
.loader-send {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader-send::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #FF3D00;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
.modal {
  display: flex;
  position: fixed;
  z-index: 1000; /* Asegurarse de que esté sobre otros elementos */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4); /* Opacidad para oscurecer el fondo */
  animation: fadeIn 0.3s;
}

.modal-content {
  background-color: #fff;
  margin: auto;
  padding: 25px;
  border: none;
  width: auto;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: #333;
  animation: scaleIn 0.3s;
}

.close {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #aaa;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: #333;
}

.modal-button {
  padding: 10px 25px;
  font-size: 16px;
  cursor: pointer;
  background-color: #3085d6;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.modal-button:hover {
  background-color: #2574a9;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.7);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
`;

document.head.appendChild(document.createElement("style")).textContent = styles;
